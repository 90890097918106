.carousel-item-padding-5-px {
  margin: 0px 0px;
  height: 100%;
}

.items-container {
  text-align: center;
}

.carousel-container {
  background: transparent;

  .react-multiple-carousel__arrow {
    border: solid;
    border-color: #e2cba1;
  }

  .react-multiple-carousel__arrow::before {
    color: #e2cba1;
  }
}
