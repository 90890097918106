.service-card-container {
  height: 350px;
  width: 350px;
  border-radius: 40px;
  background: var(--gold-linear-gradient);
  text-align: center;
  padding: 15px;
  display: flex;
}
.service-card-title {
  margin: auto;
}

@media only screen and (max-width: 1080px) {
  .service-card-container {
    height: 84px;
    width: 100%;
    border-radius: 20px;
    text-align: center;
    padding: 0px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
  }
}
