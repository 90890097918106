.hero-container {
  width: 100%;
  position: relative;
  background-size: cover;
}

.hero-text-container {
  position: absolute;
  bottom: 150px;
  left: 100px;
}

.hero-text-title {
  font-family: "EB Garamond", serif;
  font-size: 80px;
}

.hero-text-subtitle {
  font-family: "kanit", serif;
  font-size: 31px;
}

.hero-button-container {
  width: 300px;
  margin-top: 30px;
}

@media only screen and (max-width: 1080px) {
  .hero-container {
    width: 100%;
    text-align: center;
    background-size: cover;
    background-color: rgba(2, 2, 2, 0.4);
    background-blend-mode: multiply;
    display: flex;
  }

  .hero-text-container {
    position: unset;
    margin: auto;
  }

  .hero-text-title {
    font-size: 40px;
    color: white;
  }

  .hero-text-subtitle {
    font-size: 18px;
    color: white;
  }

  .hero-button-container {
    width: 150px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }
}
