.container-1 {
  margin-left: 0;
}

/* Carousel container */
.corousel-container {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 300px;
  position: relative;
}

.legal-services-title {
  color: var(--gold);
  font-family: Cairo;
  font-size: 48px;
  font-weight: 700;
  line-height: 66.82px;
  text-align: center;
  padding-bottom: 120px;
}

/* Images inside carousel */
.image-1 {
  height: 250px;
}
.Text-1 {
  color: #e2cba1;
  font-size: 30px;
  padding-top: 30px;
  height: 130px;
  padding-left: 50px;
  padding-right: 50px;
}
p {
  color: #e2cba1;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 1080px) {
  .corousel-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .legal-services-title {
    font-size: 30px;
    line-height: 0px;
    padding-bottom: 70px;
  }

  .image-1 {
    height: 150px !important;
    padding-bottom: 25px;
  }

  .Text-1 {
    color: #e2cba1;
    font-size: 20px !important;
    padding-top: 0px !important;
    height: auto !important;
    padding-bottom: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  p {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
}
