.about-us-container {
  padding: 200px 100px 200px 100px;
}

.about-us-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 89.95px;
  text-align: center;
  color: var(--gold);
}

.about-us-description {
  font-size: 32px;
  font-weight: 400;
  line-height: 59.97px;
  text-align: center;
  color: var(--gold);
}

.about-us-cards-container {
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
}

.about-us-card-text-container {
  margin: auto;
}

.about-us-card-text-1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 67.46px;
  text-align: center;
}

.about-us-card-text-2 {
  font-size: 55px;
  font-weight: 700;
  line-height: 78.98px;
  text-align: center;
}

.consultancy-container {
  display: flex;
  margin-top: 200px;
  justify-content: space-between;
}

.consultancy-container-right {
  width: 50%;

  img {
    border-radius: 45px;
    width: 100%;
  }
}

.consultancy-container-left {
  width: 50%;
  padding-right: 200px;
}

.consultancy-text {
  font-size: 25px;
  font-weight: 400;
  text-align: right;
  color: var(--gold);
  margin-top: 50px;
  padding-bottom: 150px;
  div {
    margin-bottom: 25px;
  }
}

.consultancy-button {
  width: 75%;
  margin: auto;
}

@media only screen and (max-width: 1080px) {
  .about-us-container {
    padding: 90px 7% 30px 7%;
  }

  .about-us-title {
    font-size: 30px;
  }

  .about-us-description {
    font-size: 16px;
    line-height: 30.97px;
    text-align: justify;
    text-justify: inter-word;
    direction: rtl;
  }

  .about-us-cards-container {
    display: block;
    margin-top: 50px;
    width: 100%;
  }

  .about-us-card-text-1,
  .about-us-card-text-2 {
    font-size: 20px;
    line-height: 30px;
  }
  .consultancy-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 100px;
  }

  .consultancy-container-right {
    width: 100%;

    img {
      border-radius: 20px;
    }
  }

  .consultancy-container-left {
    width: 100%;
    padding-right: 0px;
  }

  .consultancy-text {
    font-size: 16px;
    line-height: 30.46px;
    margin-top: 30px;
    padding-bottom: 30px;
    text-align: justify;
    text-justify: inter-word;
    direction: rtl;
  }

  .consultancy-button {
    width: 60%;
    margin: auto;
  }
}
