.image-slider-container {
  padding-bottom: 200px;
  position: relative;
}

.gallery-data-section {
  margin-top: 2%;
  align-items: center;
  padding-left: 15%;
  padding-right: 15%;

  .data-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 89.95px;
    text-align: center;
    color: var(--gold);
  }

  .data-title-description {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: var(--gold);
    min-height: 200px;
  }

  .data-title-sections {
    font-size: 24px;
    text-align: center;
    color: var(--gold);
  }
}

.img-class {
  height: 700px;
  /* width: 600px; */
  object-fit: cover;
}

@media only screen and (max-width: 1080px) {
  .image-slider-container {
    padding-bottom: 100px !important;
  }

  .data-title-description {
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: justify !important;
    min-height: auto !important;
  }

  .gallery-data-section {
    margin-top: 35px !important;
    padding-left: 5%;
    padding-right: 5%;
  }
}
