.search-input-container {
  position: relative;

  input {
    height: 20px;
    border-radius: 25px;
    margin-top: 9px;
    min-width: 200px;
    padding-left: 40px;
    padding-right: 7px;
    border: none;
    outline: none;
    background: var(--gold);
  }

  input:focus {
    outline: none;
  }
}

.search-icon-container {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: var(--gold);
  border: 4px solid black;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;

  img {
    margin: auto;
  }
}

@media only screen and (max-width: 1300px) {
  .search-input-container {
    input {
      min-width: 100px;
    }
  }
}
