.image-slider-container {
  padding-bottom: 200px;
  position: relative;
}

.data-section {
  align-items: center;

  .data-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 89.95px;
    text-align: center;
    color: var(--gold);
  }

  .data-title-description {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: var(--gold);
    min-height: 200px;
  }

  .data-title-sections {
    font-size: 24px;
    text-align: center;
    color: var(--gold);
  }
  /* 
    .data-description{
        font-size: 18px;
        font-weight: 700;
        line-height: 600px;
        text-align: center;
        color: var(--gold);
    } */
}

.teammember-title {
  color: var(--gold);
  font-family: Cairo;
  font-size: 48px;
  font-weight: 700;
  line-height: 66.82px;
  text-align: center;
  padding-bottom: 120px;
}

.img-class {
  height: 700px;
  /* width: 600px; */
  object-fit: cover;
}

@media only screen and (max-width: 1080px) {
  .image-slider-container {
    padding-bottom: 100px !important;
  }

  .teammember-title {
    font-size: 30px;
    line-height: 0px;
    padding-bottom: 70px;
  }

  .img-class {
    height: 200px !important;
    width: 90% !important;
    object-fit: cover;
  }

  .data-section {
    .data-title {
      font-size: 28px;
      line-height: 40px;
      padding-bottom: 25px;
      padding-top: 35px;
    }

    .data-title-description {
      font-size: 14px !important;
      font-weight: 400 !important;
      min-height: auto !important;
    }

    .data-title-sections {
      font-size: 14px !important;
    }
  }
}
