.text-field-container {
  width: 100%;
}

.text-field-input {
  border: none;
  width: calc(100% - 45px);
  height: 45px;
  font-size: 14px;
  border-radius: 6px;
  font-weight: medium;
  padding-left: 10px;
}
.text-field-input:focus {
  outline: none;
}
