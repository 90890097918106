.navbar-container {
  display: flex;
  justify-content: space-between;
  background: black;
  height: 100px;
  align-items: center;
  padding-right: 5%;
  padding-left: 5%;
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.navbar-buttons {
  a {
    font-family: "Tajawal", sans-serif;
    width: 100%;
    text-decoration: none;
    color: var(--gold);
    margin-right: 20px;
    margin-left: 20px;
    font-size: 20px;
  }
}

.navbar-logo-desktop {
  width: 180px;
}

.navbar-container-mobile {
  display: none;
}

.navbar-logo-container-mobile-controls {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .navbar-logo-desktop {
    width: 140px;
  }

  .navbar-buttons {
    a {
      font-family: "Tajawal", sans-serif;
      width: 100%;
      text-decoration: none;
      color: var(--gold);
      margin-right: 15px;
      margin-left: 15px;
      font-size: 16px;
    }
  }
}

.nav-social-media {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  width: 12%;
  justify-content: space-between;
}

.nav-social-media .button {
  margin-top: auto;
  margin-bottom: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: solid 2px;
  border-color: var(--gold);
  color: var(--gold);
  font-size: 24px;
  text-decoration: none;
  transition: transform 0.3s, background-color 0.3s;
}

.nav-social-media .button:hover {
  transform: scale(1.15);
  opacity: 0.8;
}

@media only screen and (max-width: 1080px) {
  .navbar-container {
    display: none;
  }

  .navbar-container-mobile {
    display: flex;
    flex-direction: column;
    position: fixed;
    transition: all;
    transition-duration: 500ms;
    background: black;
    width: 100vw;
    height: 100vh;
    z-index: 50;
  }

  .hamburgerOpened {
    left: 0px;
    top: 0px;
  }

  .hamburgerClosed {
    left: 100vw;
    top: 0px;
  }

  .navbar-logo-container-mobile,
  .navbar-logo-container-mobile-controls {
    padding-right: 10%;
    padding-left: 10%;
    background-color: black;
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
  }

  .navbar-logo-container-mobile-controls {
    position: sticky;
    top: 0px;
    z-index: 50;
  }

  .navbar-mobile-search-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    z-index: 51;
  }

  .mobile-menu-divider {
    width: 70%;
    max-width: 200px;
    height: 1px;
    background-color: var(--gold);
    border-radius: 25px;
    margin-left: auto;
    margin-right: 15%;
  }

  .navbar-buttons-mobile {
    li {
      text-align: right;
      padding-right: 15%;
      padding-top: 20px;
      padding-bottom: 30px;

      a {
        font-family: "Tajawal", sans-serif;
        width: 100%;
        text-decoration: none;
        color: var(--gold);
        font-size: 24px;
      }
    }
  }

  .nav-social-media-mobile {
    margin-top: auto;
    margin-bottom: 50px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-evenly;
    width: 80%;
  }
  
  .nav-social-media-mobile .button {
    margin-top: auto;
    margin-bottom: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    border: solid 2px;
    border-color: var(--gold);
    color: var(--gold);
    font-size: 24px;
    text-decoration: none;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .nav-social-media-mobile .button:hover {
    transform: scale(1.15);
    opacity: 0.8;
  }
}
