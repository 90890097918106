.footer-container {
  display: flex;
  width: 100%;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 55px;
}
.upper-footer {
  background: transparent;
  border-radius: 6px;
  width: 79%;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-content {
  color: #ffffff;
  width: 40%;
}

.footer-social-media {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-evenly;
}

.footer-address-content {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-evenly;

  div {
    color: var(--gold);
    padding:0px;
  }
}

.footer-social-media .button {
  margin-top: auto;
  margin-bottom: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  color: var(--gold);
  font-size: 24px;
  text-decoration: none;
  transition: transform 0.3s, background-color 0.3s;
}

.footer-social-media .button:hover {
  transform: scale(1.15);
  opacity: 0.8;
}

.lower-footer {
  display: flex;
  flex-direction: column;
  background: var(--gold-linear-gradient);
  align-items: center;
  width: 100%;
}

.lower-footer-content {
  color: #ffffff;
  font-size: 32px;
}

.lower-footer-textinput-button-container {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.button-container {
  width: 25%;
}

.subscribe-button {
  background: var(--gold-linear-gradient);
  border-radius: 25px;
  width: 100%;
  height: 45px;
  font-size: 14px;
  cursor: pointer;
  font-family: "Tajawal";
  border: none;
  letter-spacing: 2px;
  color: white;
}

.lower-footer-content2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 16px;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 1080px) {
  .upper-footer {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: auto !important;
  }

  .footer-content {
    text-align: justify;
    width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 20px;
  }

  .footer-social-media {
    margin-left: 0px;
    width: 100%;
    justify-content: space-evenly;
  }

  .lower-footer-content {
    text-align: center;
    font-size: 30px;
    padding-top: 30px;
  }
  .lower-footer-textinput-button-container {
    margin-top: 20px;
    width: 90%;
  }

  .subscribe-button {
    width: 100%;
  }

  .button-container {
    width: 35%;
  }

  .lower-footer-content2 {
    width: 90%;
    padding-top: 25px;
    padding-bottom: 35px;
    text-align: justify;
  }

  .footer-address-content {
    width: 100%;
  }

  .footer-logo-container{
    margin-bottom: 40px;
  }
}
